// import { Link } from "gatsby"
import React from "react"

const Banner = (props) => (

  <section className="banner_area">
    <div className="container">
      <div className="row">
        <div className="col-md-7">
          <div className="b_page_title">
            <h3>{props.title}</h3>
          </div>
        </div>
        {/*<div className="col-md-5">*/}
          {/*<ul className="list_style bread_cumb">*/}
            {/*<li className="active">*/}
              {/*<a href="index.html">Inicio</a>*/}
            {/*</li>*/}
            {/*<li>{props.title}</li>*/}
          {/*</ul>*/}
        {/*</div>*/}
      </div>
    </div>
    {/*<div className="sec_info">*/}
      {/*<div className="container">*/}
        {/*<div className="info_content">*/}
          {/*<ul className="list_style location_list">*/}
            {/*<li>*/}
              {/*<a href="tel:18004567890" className="phone">*/}
                {/*+1800 456 7890*/}
              {/*</a>*/}
            {/*</li>*/}
            {/*<li>*/}
              {/*<a href="mailto:info@unilevor.com">*/}
                {/*<i className="flaticon-mail" />*/}
                {/*info@unilevor.com*/}
              {/*</a>*/}
            {/*</li>*/}
            {/*<li>*/}
              {/*<a href="#">*/}
                {/*<i className="flaticon-localization" />*/}
                {/*56, suit 455, melborne, Australia*/}
              {/*</a>*/}
            {/*</li>*/}
          {/*</ul>*/}
          {/*<a href="#" className="request_call pull-right">*/}
            {/*Request a call back*/}
          {/*</a>*/}
        {/*</div>*/}
      {/*</div>*/}
    {/*</div>*/}
  </section>

)


export default Banner
