import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import solucion from "../../../static/img/irstrat/solucion.png"
import ganar from "../../../static/img/irstrat/ganar.png"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Value Proposition"/>
            <Banner title="Value Proposition"/>
            <section className="welcome_section_area propuesta">
                <div className="container">
                    <div className="section_title black_c">
                        <h2>Service thesis</h2>
                        <span className="sec_border"></span>
                        <p className="f_16">In IRStrat, we understand Investor Relations (IR) as a complex and strategic
                            function
                            that includes:</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="media strategy_item align-items-center">
                                <div className="round_icon"><i className="flaticon-graph"></i></div>
                                <div className="media-body">
                                    <h3>Trading</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="media strategy_item align-items-center">
                                <div className="round_icon"><i className="flaticon-money-1"></i></div>
                                <div className="media-body">
                                    <h3>Corporate Finance</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="media strategy_item align-items-center">
                                <div className="round_icon"><i className="fa fa-handshake-o" aria-hidden="true"></i>
                                </div>
                                <div className="media-body">
                                    <h3>Communication</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="media strategy_item align-items-center">
                                <div className="round_icon"><i className="flaticon-research"></i></div>
                                <div className="media-body">
                                    <h3>Strategic PR</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="media strategy_item align-items-center">
                                <div className="round_icon"><i className="flaticon-money-2"></i></div>
                                <div className="media-body">
                                    <h3>Finance law</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="media strategy_item align-items-center">
                                <div className="round_icon"><i className="fa fa-bullhorn" aria-hidden="true"></i></div>
                                <div className="media-body">
                                    <h3>Marketing</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="offer_area_two propuesta">
                <div className="container">
                    <div className="row strategy_info align-items-center">
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h2>Effective solution</h2>
                                <span className="sec_border"></span>

                                <p>We have assembled a
                                    multidisciplinary
                                    team
                                    and developed strategic partnership network gathering the knowledge and expertise
                                    required to fully
                                    cover IR core areas, with the goal of providing effective solutions, building a
                                    competitive edge over
                                    other agencies whose services are limited to PR activities or some back office
                                    processes.</p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div>
                                <img className="video_img wow fadeInRight" data-wow-delay="0.2s"
                                     data-wow-duration="2.0s"
                                     src={solucion} alt={" "}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="b_consultation_area section_gap propuesta">
                <div className="container">
                    <div className="b_consultation_content">
                        <h2>Inside out</h2>
                        <p>We ground our advantage in our expertise and extensive network of contacts in the Mexican
                            market; we deem linking and exposure to international markets as strategic aspects, which we
                            constantly strengthen; however, we also believe that only a consulting firm established in
                            Mexico can effectively and fully meet the complex needs of Mexican companies.</p>
                    </div>
                </div>
            </section>
            <section className="offer_area_two propuesta bg-white">
                <div className="container">
                    <div className="row strategy_info align-items-center">
                        <div className="col-lg-5">
                            <div>
                                <img className="video_img wow fadeInLeft" data-wow-delay="0.2s" data-wow-duration="2.0s"
                                     src={ganar} alt={" "}/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h2>Win-win</h2>
                                <span className="sec_border"></span>

                                <p>We look after and maximize your
                                    investment, for this
                                    reason, we have developed our own analytics, monitoring and measurement tools,
                                    seeking to provide the
                                    most advanced solutions that meet your specific needs, without undermining your
                                    investment and time in
                                    multiple teams and budgets.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
